.Main-Content {
  padding: 0;
}

body {
  background-color: #141414;
}

.visa {
  margin-top: -50px;
}

.profile-card {
  margin: 50px;
  border-radius: 25px;
  width: 700px;
}

.footer {
  height: auto;
}

.login-form {
  max-width: 300px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.register-form {
  width: 400px;
  max-width: 350px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.login-form-button {
  width: 100%;
}

.amount-negative {
  background-color: #ffccc7;
}

.amount-positive {
  background-color: #d9f7be;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.contract {
  padding: 50px;
}

.contract ol {
  counter-reset: section;
  /* Creates a new instance of the
                               section counter with each ol
                               element */
  list-style-type: none;
}

.contract li::before {
  counter-increment: section;
  /* Increments only this instance
                                              of the section counter */
  content: counters(section, ".") " ";
  /* Combines the values of all instances
                                            of the section counter, separated
                                            by a period */
}

input[name="btn_text"]:hover {
  background: url(../public/img/pay-btn-active.png) left top no-repeat;
}

#brelok-logo {
  height: 150px;
}

#pay-logo {
  height: 100px;
}



@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  .Main-Content {
    padding: 0;
  }

  .ant-menu-overflow {
    padding: 0;
    display: block;
  }

  .ant-layout-header {
    padding: 0;
    text-align: center;
  }

  .profile-card {
    margin: 0;
    border-radius: 0;
    width: auto;
  }

  .ant-card .ant-card-head {
    margin-top: 1px;
    padding-top: 0;
    padding-left: 5px;
    padding-right: 0;
  }

  .ant-card .ant-card-body {
    padding: 15px;
  }

  .layout {
    display: block;
  }

  .login-form,
  .register-form {
    padding: 15px;
    margin: 0;
  }

  .contract {
    padding: 0;
  }
}